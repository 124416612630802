import loadable from '@loadable/component'
import { globalHistory, Router } from '@reach/router'
import React from 'react'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import AuthenticatedLayout from '~/layouts/AuthenticatedLayout'
import { BaseNotFoundPage } from '~/routes/404/BaseNotFoundPage'

const AccountAddons = loadable(() => import('~/routes/AccountAddons'))
const AccountBrowseRecipes = loadable(
  () => import('~/routes/AccountBrowseRecipes/AccountBrowseRecipes')
)
const AccountBoxSettings = loadable(() => import('~/routes/AccountBoxSettings'))
const AccountCancelled = loadable(
  () => import('~/routes/AccountCancelled/AccountCancelled')
)
const AccountCustomize = loadable(
  () => import('~/routes/AccountCustomize/AccountCustomize')
)
const AccountDeals = loadable(
  () => import('~/routes/AccountDeals/AccountDeals')
)
const AccountHelp = loadable(() => import('~/routes/AccountHelp/AccountHelp'))
const AccountMembershipReview = loadable(
  () => import('~/routes/AccountMembershipReview/AccountMembershipReview')
)
const AccountWhatsForDinner = loadable(
  () => import('~/routes/AccountWhatsForDinner/AccountWhatsForDinner')
)

const AccountSavedRecipes = loadable(
  () => import('~/routes/AccountSavedRecipes/AccountSavedRecipes')
)
const AccountYourBox = loadable(() => import('~/routes/AccountYourBox'))
const AccountCredits = loadable(() => import('~/routes/Credits'))
const AccountOrderHistory = loadable(
  () => import('~/routes/AccountOrderHistory')
)
const AccountProfilePayment = loadable(
  () => import('~/routes/AccountProfilePayment')
)
const AccountDelayCancelFlow = loadable(
  () => import('~/routes/AccountDelayCancelFlow')
)
const RecipePage = loadable(() => import('~/routes/RecipePage/RecipePage'))

const WelcomeGuide = loadable(
  () => import('~/routes/AccountWelcomeGuide/WelcomeGuide')
)
export default function Account() {
  const [title, setTitle] = React.useState()

  const [welcomeGuide] = useOptimizelyFeature('welcome_guide')

  React.useEffect(() => {
    function getPathFromWindow() {
      const match = Object.keys(titleMap).find((x) =>
        location.pathname.startsWith(`/account${x}`)
      )

      if (match) setTitle(titleMap[match])
    }

    getPathFromWindow()

    return globalHistory.listen(getPathFromWindow)
  }, [])

  return (
    <AuthenticatedLayout bg="bb.ivory" title={title}>
      <Router basepath="/account" style={{ display: 'contents' }}>
        <AccountAddons path="addons" />
        <AccountBoxSettings path="box-settings/*" />
        <AccountBrowseRecipes path="recipes" />
        {welcomeGuide && <WelcomeGuide path="welcome-guide" />}
        <AccountCancelled path="canceled" />
        <AccountCredits path="credits" />
        <AccountCustomize path="customize-your-box" />
        <AccountDelayCancelFlow path="delay/*" />
        <AccountDeals path="deals" />
        <AccountHelp path="help/*" />
        <AccountMembershipReview path="membership-review" />
        <AccountWhatsForDinner path="whats-for-dinner" />
        <AccountSavedRecipes path="saved-recipes" />
        <AccountOrderHistory path="order-history/*" />
        <AccountProfilePayment path="profile-payment/*" />
        <AccountYourBox path="your-box/*" />
        <RecipePage path="recipe/:slug" />
        <BaseNotFoundPage
          default
          message={
            "We can't seem to find this page. Please double-check the URL or go back to the homepage."
          }
        />
      </Router>
    </AuthenticatedLayout>
  )
}

const titleMap = {
  '/addons': 'Essential Add-ons',
  '/box-settings': 'Box Settings',
  '/canceled': 'Resubscribe to ButcherBox',
  '/credits': 'Refer a Friend',
  '/customize-your-box': 'Customize Your Box',
  '/deals': 'Exclusive Member Deals',
  '/help': 'Help',
  '/order-history': 'Order History',
  '/membership-review': 'Membership Review',
  '/whats-for-dinner': "What's For Dinner",
  '/saved-recipes': 'Saved Recipes',
  '/profile-payment': 'Profile & Payment Settings',
  '/your-box': 'Next Box',
}
